import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { LinearProgress } from '@material-ui/core'
import { Card, Typography, Section, Icon } from '.'
import theme from '../../theme'

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        position: 'relative'
    },
    progress: {
        padding: theme.spacing(2),
        '& .bar': {
            display: 'flex',
            alignItems: 'center',
            '& .icon': {
                margin: theme.spacing(0, 1),
                width: '32px',
                [theme.breakpoints.down("xs")]: {
                    margin: theme.spacing(0, 0.5),
                }
            },
            '& .MuiLinearProgress-root': {
                width: '65%',
                height: 12,
                margin: theme.spacing(0, 1.5),
                borderRadius: 12,
                [theme.breakpoints.down("xs")]: {
                    width: '50%'
                }
            },
            '& .MuiLinearProgress-colorPrimary': {
                backgroundColor: theme.palette.light.light
            }
        }
    }
}))

function StreakStatus({ streak, streakGoal = 14 }) {
    const classes = useStyles()

    let percentage = (() => {
        return (streak / streakGoal) * 100
    })()

    return (
        <Card hideOverflow className={classes.root}>
            <Section title="Daily medicine streak">
                <div className={classes.progress}>
                    <div className="bar">
                        <Typography variant="caption" align="center">
                            {streak}/{streakGoal}
                        </Typography>
                        <LinearProgress variant="determinate" value={percentage} />
                        <Typography variant="caption" align="center">
                            {streakGoal - streak} more to earn 3x bones
                        </Typography>
                        <Icon name="bone" className="icon" bg={theme.palette.primary.main} fg={theme.palette.light.light} />
                    </div>
                </div>
            </Section>
        </Card>
    )
}

export default StreakStatus
