import React, { useContext } from 'react'
import { CheckboxProps, RadioGroupProps, ButtonProps, Checkbox, RadioGroup } from '@material-ui/core'
import { Field, FieldContext, SubmitButtonComponent } from '@parallelpublicworks/entitysync/'
import { TimePicker, TimePickerProps, MuiPickersUtilsProvider, MaterialUiPickersDate } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { Button } from './library'

interface RadioGroupFieldProps extends RadioGroupProps {
  field: string;
}
export const RadioGroupField = (props: RadioGroupFieldProps) => {
  function RadioGroupComponent(){
    const [fieldValue, setFieldValue] = useContext(FieldContext);
    const onChange = typeof props.onChange !== 'undefined' ? (e, val) => {
      setFieldValue(val);
      props.onChange(e, val);
    } : (e, val) => {setFieldValue(val)};
    return (
      <RadioGroup {...props} value={fieldValue} onChange={onChange}>
        {props.children}
      </RadioGroup>
    );

  }
  return (
    <Field field={props.field}>
      <RadioGroupComponent/>
    </Field>
  )
};

interface CheckboxValueProps extends CheckboxProps {
  value: string;
}
export const CheckboxValue = (props: CheckboxValueProps) => {
  const [fieldValue, setFieldValue] = useContext(FieldContext);
  const valueSet = !Array.isArray(fieldValue) ? new Set([]) : new Set(fieldValue);

  function setCheckboxValue(e){
    if (e.target.checked) {
      if (!valueSet.has(props.value)) {
        valueSet.add(props.value);
        setFieldValue(Array.from(valueSet));
      }
    }
    else{
      if (valueSet.has(props.value)) {
        valueSet.delete(props.value);
        setFieldValue(Array.from(valueSet));
      }
    }
  }
  const onChange = typeof props.onChange !== 'undefined' ? (e) => {
    setCheckboxValue(e);
    props.onChange(e);
  } : (e) => {
    setCheckboxValue(e);
  };
  return (
    <Checkbox {...props} defaultChecked={valueSet.has(props.value)} onChange={onChange} />
  );
};

interface SubmitButtonProps extends ButtonProps {
  validate?: boolean,
  clear?: boolean,
  field?: string
}
export const SubmitButton = (props: SubmitButtonProps) => {
  function FieldButton() {
    const [fieldValue, setFieldValue] = useContext(FieldContext);
    const onClick = typeof props.onClick !== 'undefined' ? (e) => {
      props.onClick(e);
      setFieldValue(true);
    } : (e) => {
      setFieldValue(true);
    }
    const Submit = SubmitButtonComponent((props: SubmitButtonProps) => {
      return (
        <Button {...props} onClick={onClick}>{props.children}</Button>
      )
    })
    return (
      <Submit {...props} />
    );
  }

  if (!props.field) {
    const Submit = SubmitButtonComponent((props: SubmitButtonProps) => {
      return (
        <Button {...props}>{props.children}</Button>
      )
    })
    return ( <Submit {...props} /> );
  }

  return ( 
    <Field field={props.field}>
      <FieldButton />
    </Field>
  )
};

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
interface TimePickerFieldProps extends Omit<TimePickerProps, 'value'> {
  field: string,
  onChange?: (date: Date) => void
}
export const TimePickerField = (props: TimePickerFieldProps) => {
  function TimePickerComponent(){
    const [fieldValue, setFieldValue] = useContext(FieldContext);
    const onChange = typeof props.onChange !== 'undefined' ? (date: MaterialUiPickersDate) => {
      setFieldValue(date.toISOString());
      props.onChange(date);
    } : (date: MaterialUiPickersDate) => {
      setFieldValue(date.toISOString());
    };
    const date = new Date(fieldValue);
    return (
      <MuiPickersUtilsProvider utils={MomentUtils} >
        <TimePicker {...props} value={date instanceof Date && !isNaN(date.valueOf()) ? date : null} onChange={onChange} InputProps={{ disableUnderline: true }}/>
      </MuiPickersUtilsProvider>
    );

  }
  return (
    <Field field={props.field}>
      <TimePickerComponent/>
    </Field>
  )
};
