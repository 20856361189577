import * as React from 'react'
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'
import { makeStyles, Radio, FormControlLabel, Container } from '@material-ui/core'
import { DateTime } from 'luxon'
import { useState, useContext, useEffect } from 'react'
import { Entity, Field, FieldContext } from '@parallelpublicworks/entitysync'
import { CheckboxValue, RadioGroupField, SubmitButton } from '../components/materialEntitysync'
import { ImpactContext } from '../components/Layout'
import { Section, CircularProgress, Spacer, Typography, Avatar, ReportTimeline, BackButton } from '../components/library'
import { SliderField, InputField } from '../components/library/entitysync'
import setBonesToBeAdded from '../utils/setBonesToBeAdded'
import StreakStatus from '../components/library/StreakStatus'

const useStyles = makeStyles(theme => {
  return {
    header: {
      padding: theme.spacing(0, 0, 1),
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      minWidth: 360,
      '& > * ': {
        marginRight: '0.5em'
      },
      '& .MuiTypography-caption': {
        // marginTop: '0.5em',
        marginRight: 0,
        width: '100%'
      }
    },
    title: {
      textAlign: 'left'
    },
    avatar: {
      width: 40,
      height: 40,
      [theme.breakpoints.down('md')]: {
        width: 30,
        height: 30
      },
      [theme.breakpoints.down('xs')]: {
        width: 25,
        height: 25
      }
    },
    input: {
      width: '100%',
      borderRadius: '5px',
      paddingLeft: '20px'
    },
    checkInActions: {
      display: 'flex',
      justifyContent: 'flex-end',
      flexDirection: 'row',
      marginTop: '51px',
      marginBottom: '51px'
    },
    questionLabel: {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '36px'
    }
  }
})

interface Symptom {
  field_time: Date
  field_location: string
  field_trigger: string
}
const AsthmaCheckInFormPage: React.FC<AsthmaCheckInFormProps> = ({ location: { state } }) => {
  const { profiles, todaysCheckIn, currentWeek, currentStreaks } = useContext(ImpactContext)
  const [loading, setLoading] = useState(false);
  const rawSymptoms = state && state.symptoms ? state.symptoms : null;
  const puckReset = state && state.puckReset ? state.puckReset : null;
  const symptoms = rawSymptoms ? rawSymptoms.map((s: any) => ({ time: new Date(s.time).toISOString() })) : null
  const classes = useStyles()
  const ShowIfYes = ({ children }) => {
    const [yesNoContextObj] = useContext(FieldContext)
    const yesNoContext = yesNoContextObj && yesNoContextObj.value ? yesNoContextObj.value : yesNoContextObj
    return yesNoContext === 'yes' ? <>{children}</> : <></>
  }
  const [entityAttrs, setEntityAttrs] = useState(null)
  const [symptomsData, setSymptomsData] = useState(symptoms ? symptoms : null)
  const [changed, setChanged] = useState(null)

  let child_label = 'your child'
  if (profiles && profiles?.child && profiles.child?.name && profiles.child.name.length > 0) {
    child_label = profiles.child.name
  }

  useEffect(() => {
    if (todaysCheckIn !== null && typeof todaysCheckIn !== 'undefined') {
      setEntityAttrs({ source: todaysCheckIn })
      if (todaysCheckIn?.attributes?.field_symptoms && todaysCheckIn.attributes.field_symptoms.length > 0) {
        setSymptomsData(JSON.parse(todaysCheckIn.attributes.field_symptoms))
      }
      if (todaysCheckIn?.attributes?.changed && changed !== null) {
        if (todaysCheckIn.attributes.changed >= changed) {
          let check_ins_to_go = currentWeek.check_ins_left - 1
          let desc
          if (check_ins_to_go < 1) {
            desc = `You completed your daily check-in goal, Keep it up!`
          } else {
            desc =
              check_ins_to_go === 1
                ? `Keep it up! ${currentWeek.check_ins_left - 1} more check-in to go this week to earn 2x`
                : `Keep it up! ${currentWeek.check_ins_left - 1} more check-ins to go this week to earn 2x`
          }
          setLoading(true)
          setBonesToBeAdded(null, DateTime.now().toISO(), desc, false, 'Good job with checking in!', true)
          if (todaysCheckIn?.attributes?.field_finish === true) {
            navigate('/reports')
          } else {
            navigate('/')
          }
        }
      }
    } else {
      setEntityAttrs({})
    }
  }, [todaysCheckIn])

  const appendData = (unsavedChanges, entityData) => {
    const changed_time = entityData?.attributes?.changed ? entityData.attributes.changed : Math.round(DateTime.now().toMillis() / 1000)
    setChanged(changed_time)
    if (!unsavedChanges.attributes) unsavedChanges.attributes = {}
    unsavedChanges.attributes.field_checkin_date_iso = DateTime.now().toISO()
    if (symptomsData) {
      unsavedChanges.attributes.field_symptoms = JSON.stringify(symptomsData)
    }
    unsavedChanges.attributes.title = 'Asthma Check In'
    setLoading(true)
  }

  const onSave = (e, unsavedChanges, entityData) => {
    appendData(unsavedChanges, entityData)
  }

  const onFinish = (e, unsavedChanges, entityData) => {
    appendData(unsavedChanges, entityData)
    unsavedChanges.attributes.field_finish = true
  }

  if (entityAttrs === null || profiles === null) return <CircularProgress />

  return (
    <Entity type="node--asthma_check_in" {...entityAttrs} componentId="asthma-check-in-entity">
      <Container maxWidth="md">
        <Spacer />
        <BackButton to="/">Back</BackButton>
        <Spacer amount="1.5" />
        <div className={classes.header}>
          <Typography variant="h1" shade="dark" className={classes.title}>
            Daily Check-in
          </Typography>
          {profiles !== null &&
            [profiles.child, profiles.parent].map(profile => (
              <Avatar tooltip className={classes.avatar} key={profile.id} profile={profile} />
            ))}
        </div>
        <Typography variant="caption" shade="dark">
          {DateTime.now().toLocaleString(DateTime.DATE_HUGE)}
        </Typography>
        <Spacer amount="1" />
        <StreakStatus streak={currentStreaks} />
        <Spacer amount="1" />
        <Typography variant="caption" shade="dark">
          {symptoms && symptoms.length > 0
            ? `${child_label} reported ${symptoms.length} symptoms from the watch. Check in together to discuss how ${child_label} is feeling.`
            : `No symptoms reported from the watch. Check in together to discuss how ${child_label} is feeling.`}
        </Typography>
        <Spacer amount="2" />
        <ReportTimeline
          disabled={loading}
          data={symptomsData}
          edit
          onDelete={(e, iso, new_data) => {
            // console.log('DELETED AN ITEM', iso, new_data)
            setSymptomsData(new_data)
          }}
          onAdd={(e, item, new_data) => {
            // console.log('ADDED AN ITEM', item, new_data);
            setSymptomsData(new_data)
          }}
          onEdit={(e, item, new_data) => {
            // console.log('EDITED AN ITEM', item, new_data);
            setSymptomsData(new_data)
          }}
        />
        <Spacer />
        <Section title={`Questions for ${child_label}`} profiles={[profiles.child]}>
          <label className={classes.questionLabel}></label>
          <div className={classes.sliderRow}>
            <SliderField field="field_asthma_level" label="How’s your asthma today?" mood disabled={loading} />
          </div>
        </Section>
        <Section title="Medication Questions" profiles={[profiles.child]}>
          <label className={classes.questionLabel}>Did you take your rescue inhaler?</label>
          <RadioGroupField field="field_inhaler_used" row>
            <FormControlLabel value="yes" control={<Radio color="primary" />} label={'Yes'} disabled={loading} />
            <FormControlLabel value="no" control={<Radio color="primary" />} label={'No'} disabled={loading} />
          </RadioGroupField>
          <Field field="field_inhaler_used">
            <ShowIfYes>
              <div>
                <label className={classes.questionLabel}>How many doses did you use?</label>
                <InputField
                  key="field_inhaler_doses"
                  field="field_inhaler_doses"
                  inputProps={{ min: 1 }}
                  disableUnderline
                  color="primary"
                  className={classes.input}
                  type="number"
                  disabled={loading}
                />
              </div>
              <div>
                <label className={classes.questionLabel}>When was last dose?</label>
                <InputField
                  inputVariant="standard"
                  key="field_last_dose_str"
                  field="field_last_dose_str"
                  color="primary"
                  className={classes.input}
                  disabled={loading}
                  type="time"
                />
              </div>
            </ShowIfYes>
          </Field>
          <label className={classes.questionLabel}>Did you take your daily medication today?</label>
          <RadioGroupField field="field_medication_used" row>
            <FormControlLabel value="yes" control={<Radio color="primary" />} label={'Yes'} disabled={loading} />
            <FormControlLabel value="no" control={<Radio color="primary" />} label={'No'} disabled={loading} />
          </RadioGroupField>
          <Field field="field_medication_used">
            <ShowIfYes>
              <div>
                <label className={classes.questionLabel}>When did you take it?</label>
                <div>
                  <Field field="field_medication_use_period">
                    <FormControlLabel control={<CheckboxValue value="AM" color="primary" />} label={'AM'} disabled={loading} />
                    <FormControlLabel control={<CheckboxValue value="PM" color="primary" />} label={'PM'} disabled={loading} />
                  </Field>
                </div>
              </div>
            </ShowIfYes>
          </Field>
        </Section>
        <div className={classes.checkInActions}>
          <SubmitButton color="tertiary" variant="contained" onSubmit={onSave} disabled={loading}>
            Save for later
          </SubmitButton>
          <SubmitButton color="primary" variant="contained" onSubmit={onFinish} disabled={loading}>
            Finish
          </SubmitButton>
        </div>
      </Container>
    </Entity>
  )
}
const asthmaCheckInFormPropTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    hash: PropTypes.string.isRequired,
    state: PropTypes.shape({
      checkInDate: PropTypes.instanceOf(Date),
      symptoms: PropTypes.arrayOf(
        PropTypes.shape({
          field_time: PropTypes.instanceOf(Date).isRequired,
          field_location: PropTypes.string,
          field_trigger: PropTypes.string
        })
      )
    })
  }).isRequired
}
type AsthmaCheckInFormProps = PropTypes.InferProps<typeof asthmaCheckInFormPropTypes>
AsthmaCheckInFormPage.propTypes = asthmaCheckInFormPropTypes

export default AsthmaCheckInFormPage
